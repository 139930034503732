:root {
    // colors
    --primary-color: #404040;
    --primary-text-color: #FFFFFF;
    --primary-color-light: #f2f7f9;
    --secondary-color: #cc0000;
    --secondary-text-color: #FFFFFF;
    --secondary-color-light: #f2f7f9;
    --tertiary-color: #111;
    --tertiary-text-color: #ffffff;
    --success-color: #21af34;
    --warning-color: #e4b32c;
    --error-color: #ff4646;
    --info-color: #4687ff;
    --header-color: #fff;
    --box-border: #d9e3ea;
    --page-background: #FFFFFF;
    --primary-color-opacity: rgba(12, 107, 147, 0.05);
    --divider-color: #747474;
    --sidenav-shadow-color: rgba(0, 0, 0, 0.73);
    --side-background: #F8F8F8;
    --side-menu-active: #404040;
    --side-menu-active-text: #fff;
    --input-color: #000;
    --input-placeholder-color: #b1b1b1;

    // alert colors
    --alert-shadow: rgba(0, 0, 0, 0.5);
    --alert-container: #e6e6e6;

    // confirm colors
    --confirm-shadow: rgba(0, 0, 0, 0.5);
    --confirm-container: #e6e6e6;

    // sizes
    --header-height: 84px;
    --header-tablet-break-height: 64px;

    // transitions
    --sidenav-transition-time: 0.2s;
}